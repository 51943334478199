import React from "react";
import classes from './WhoWeAre.module.scss';
import { useTranslation } from "react-i18next";

const WhoWeAre = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.whoWeAre}>
                <h2 className={classes.title}>
                    {t('who_we_are_title')}
                </h2>
                <div className={classes.cols}>
                    <div className={classes.col}>
                        <p>
                            {t('who_we_are_text_01')}
                        </p>
                        <p>
                            {t('who_we_are_text_02')}
                        </p>
                    </div>
                    <div className={classes.col}>
                        <p>
                            {t('who_we_are_text_03')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhoWeAre;
