import React from "react";
import Who from "../../components/AboutComponents/Who/Who";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import SubBlock from "../../components/AboutComponents/SubBlock/SubBlock";
import Benefits from "../../components/AboutComponents/Benefits/Benefits";

const AboutPage = () => {
  return (
    <div>
      <Who />
      <SubBlock />
      <Benefits />
      <Footer />
    </div>
  );
};

export default AboutPage;
