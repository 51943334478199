import React from "react";
import classes from "./StartTrade.module.scss";
import manImg from "../../../assets/img/Home/man-img.png";
import { useTranslation } from "react-i18next";

const StartTrade = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.startTradeWrap}>
      <div className="container">
        <div className={classes.startTradeRow}>
          <div className={classes.col8}>
            <div className={classes.startTradeAccordionWrap}>
              <div className={classes.startTradeAccordionItem}>
                <h4 className={classes.startTradeAccordionItemTitle}>{t('home_starttrade_01_title')}</h4>
                <a href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`} className="btn-default">{t('start_trading')}</a>
              </div>
              <div className={classes.startTradeAccordionItemHidden}>
                <p className={classes.startTradeAccordionItemHiddenText}>
                  {t('home_starttrade_01_text')}
                </p>
              </div>
            </div>
            <div className={classes.startTradeAccordionWrap}>
              <div className={classes.startTradeAccordionItem}>
                <h4 className={classes.startTradeAccordionItemTitle}>{t('home_starttrade_02_title')}</h4>
                <a href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`} className="btn-default">{t('invest_now')}</a>
              </div>
              <div className={classes.startTradeAccordionItemHidden}>
                <p className={classes.startTradeAccordionItemHiddenText}>
                  {t('home_starttrade_02_text')}
                </p>
              </div>
            </div>
            <div className={classes.startTradeAccordionWrap}>
              <div className={classes.startTradeAccordionItem}>
                <h4 className={classes.startTradeAccordionItemTitle}>{t('home_starttrade_03_title')}</h4>
                <a href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`} className="btn-default">{t('buy_crypto_right_now')}</a>
              </div>
              <div className={classes.startTradeAccordionItemHidden}>
                <p className={classes.startTradeAccordionItemHiddenText}>{t('home_starttrade_03_text')}</p>
              </div>
            </div>
            <div className={classes.startTradeAccordionWrap}>
              <div className={classes.startTradeAccordionItem}>
                <h4 className={classes.startTradeAccordionItemTitle}>{t('home_starttrade_04_title')}</h4>
                <a href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`} className="btn-default">{t('open_demo_account')}</a>
              </div>
              <div className={classes.startTradeAccordionItemHidden}>
                <p className={classes.startTradeAccordionItemHiddenText}>{t('home_starttrade_04_text')}</p>
              </div>
            </div>
          </div>
          <div className={classes.col4}>
            <img src={manImg} alt=""/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartTrade;
