import React from "react";
import classes from "./Have.module.scss";
import { Trans, useTranslation } from "react-i18next";

const Have = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.have}>
      <div className={classes.haveBody}>
        <div className="container">
          <div className={classes.haveTitle}>
            <Trans
              components={{ span: <span></span> }}
            >
              {t('four_types_title')}
            </Trans>
          </div>
          <div className={classes.haveContentWrap}>
            <div className={classes.haveContent}>
              <div className={classes.haveRowTop}>
                <div className={classes.haveRowTopColumn}>{t('four_types_minimum_deposit')}</div>
                <div className={classes.haveRowTopColumn}>{t('four_types_maximal_leverage')}</div>
                <div className={classes.haveRowTopColumn}>{t('four_types_minimum_lot')}</div>
                <div className={classes.haveRowTopColumn}>{t('four_types_maximum_lot')}</div>
              </div>
              <div className={classes.haveRowBot}>
                <div className={classes.haveRowBotColumn}>249</div>
                <div className={classes.haveRowBotColumn}>2000</div>
                <div className={classes.haveRowBotColumn}>0.01</div>
                <div className={classes.haveRowBotColumn}>100.0</div>
              </div>
            </div>
            <div className={classes.haveContent}>
              <div className={classes.haveRowTop}>
                <div className={classes.haveRowTopColumn}>{t('four_types_comission')}</div>
                <div className={classes.haveRowTopColumn}>{t('four_types_spread')}</div>
                <div className={classes.haveRowTopColumn}>{t('four_types_decimals')}</div>
                <div className={classes.haveRowTopColumn}>{t('four_types_scalping_allowed')}</div>
              </div>
              <div className={classes.haveRowBot}>
                <div className={classes.haveRowBotColumn}>0.0</div>
                <div className={classes.haveRowBotColumn}>{t('four_types__variable')}</div>
                <div className={classes.haveRowBotColumn}>5 {t('four_types__decimals')}</div>
                <div className={classes.haveRowBotColumn}>{t('four_types__yes')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Have;
