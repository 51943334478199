import React from "react";
import classes from './Graphic.module.scss';
import graph_01 from '../../../assets/img/VattenfallPage/Graphic/graph_01.png';
import graph_02 from '../../../assets/img/VattenfallPage/Graphic/graph_02.png';
import graph_03 from '../../../assets/img/VattenfallPage/Graphic/graph_03.png';
import { useTranslation } from "react-i18next";

const Graphic = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.graphic}>
                <h2 className={classes.title}>
                    {t('vfall_graph_title')}
                </h2>
                <div className={classes.images}>
                    <div className={classes.graph}>
                        <img src={graph_01} alt=''/>
                    </div>
                    <div className={classes.graph}>
                        <img src={graph_02} alt=''/>
                    </div>
                    <div className={classes.graph}>
                        <img src={graph_03} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Graphic;
