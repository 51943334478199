import React from "react";
import { useTranslation } from "react-i18next";

const ThirdTab = () => {
  const { t } = useTranslation();
  return (
    <p>
      {t('main_instruments_03_text')}
    </p>
  );
};

export default ThirdTab;
