import React from "react";
import classes from "./Footer.module.scss";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <div className="container">
      <div className={classes.footer}>
        <div className={classes.col1}>
          <div className={classes.thisWebsite}>
            {t('footer_this_website')}
          </div>
          <div className={classes.disclaimer}>
            <Trans
              components={{ span: <span></span> }}
            >
              {t('footer_disclaimer')}
            </Trans>
          </div>
          <div className={classes.about}>
            <Trans
              components={{ span: <span></span> }}
            >
              {t('footer_about')}
            </Trans>
          </div>
          <div className={classes.info}>
            <Trans
              components={{ span: <span></span> }}
            >
              {t('footer_info')}
            </Trans>
          </div>
        </div>
        <div className={classes.col2}>
          <div className={classes.item}>
            <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
              {t('footer_privacy_policy')}
            </a>
          </div>
          <div className={classes.item}>
            <a href="/docs/AML_POLICY.pdf" target="_blank">
              {t('footer_aml')}
            </a>
          </div>
          <div className={classes.item}>
            <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
              {t('footer_terms')}
            </a>
          </div>
        </div>
        <div className={classes.col3}>
          <div>
            {t('footer_contact_us')}
          </div>
          <div className={classes.par}>
            <Trans
              components={{ span: <span></span> }}
            >
              {t('footer_contacts')}
            </Trans>
          </div>
          <div className={classes.par}>
            <a href='tel:+61290598543'>
              +61290598543
            </a>
          </div>
          <div className={classes.par}>
            <a href={`mailto:support@${hostname}`}>
              support@{hostname}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Footer;
