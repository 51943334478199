import React from "react";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/TradingConditionsPage/Banner/Banner";
import Commission from "../../components/TradingConditionsPage/Commission/Commission";
import ForexSwap from "../../components/TradingConditionsPage/ForexSwap/ForexSwap";
import Industry from "../../components/TradingConditionsPage/Industry/Industry";
import Spreads from "../../components/TradingConditionsPage/Spreads/Spreads";
import TradingHours from "../../components/TradingConditionsPage/TradingHours/TradingHours";

const TradingConditionsPage = () => {
    return (
        <div>
            <Banner />
            <Spreads />
            <Industry />
            <Commission />
            <TradingHours />
            <ForexSwap />
            <Footer />
        </div>
    );
};

export default TradingConditionsPage;
