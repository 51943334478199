import React from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/VattenfallPage/Banner/image.png';

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={classes.title}>
                        <Trans>
                            {t('vfall_banner_title')}
                        </Trans>
                    </h1>
                    <div className={classes.text}>
                        <p>
                            {t('vfall_banner_text_01')}
                        </p>
                        <p>
                            {t('vfall_banner_text_02')}
                        </p>
                        <p>
                            {t('vfall_banner_text_03')}
                        </p>
                        <p>
                            {t('vfall_banner_text_04')}
                        </p>
                    </div>
                    <a href='https://user.vex-change.online/signup' id='btn' className={[classes.btn, 'btn-default'].join(' ')}>
                        {t('start_trading')}
                    </a>
                </div>
                <img className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default Banner;
