import React, { useEffect, useState } from "react";
import classes from './Commission.module.scss';
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const rows = [
    [
        {
            left: 'AUD Australian Dollar',
            center: {
                text_01: 'AUD $0.03 (', text_02: '$0.06)'
            },
            right: {
                text_01: 'AUD $3 (', text_02: '$6)'
            },
        },
        {
            left: 'USD US Dollar',
            center: {
                text_01: 'USD $0.03 (', text_02: '$0.06)'
            },
            right: {
                text_01: 'USD $3 (', text_02: '$6)'
            },
        },
        {
            left: 'GBP Pound Sterling',
            center: {
                text_01: 'GBP £0.02 (', text_02: '£0.04)'
            },
            right: {
                text_01: 'GBP £2 (', text_02: '£4)'
            },
        },
        {
            left: 'EUR Euro',
            center: {
                text_01: 'EUR €0.025 (', text_02: '€0.05)'
            },
            right: {
                text_01: 'EUR €2.5 (', text_02: '€5)'
            },
        },
        {
            left: 'SGD Singapore Dollar',
            center: {
                text_01: 'SGD $0.035 (', text_02: '$0.07)'
            },
            right: {
                text_01: 'SGD $3.50 (', text_02: '$7)'
            },
        },
        {
            left: 'JPY Japanese Yen',
            center: {
                text_01: 'JPY ¥2.75 (', text_02: '¥5.50)'
            },
            right: {
                text_01: 'JPY ¥275 (', text_02: '¥550)'
            },
        },
        {
            left: 'NZD New Zealand Dollar',
            center: {
                text_01: 'ANZD $0.0325 (', text_02: '$0.065)'
            },
            right: {
                text_01: 'NZD $3.25 (', text_02: '$6.50)'
            },
        },
        {
            left: 'CAD Canadian Dollar',
            center: {
                text_01: 'CAD $0.03 (', text_02: '$0.06)'
            },
            right: {
                text_01: 'CAD $3 (', text_02: '$6)'
            },
        },
    ],
    [
        {
            left: 'AUD Australian Dollar',
            center: {
                text_01: 'AUD $0.02 (', text_02: '$0.04)'
            },
            right: {
                text_01: 'AUD $2 (', text_02: '$4)'
            },
        },
        {
            left: 'USD US Dollar',
            center: {
                text_01: 'USD $0.15 (', text_02: '$0.03)'
            },
            right: {
                text_01: 'USD $1,5 (', text_02: '$3)'
            },
        },
        {
            left: 'GBP Pound Sterling',
            center: {
                text_01: 'GBP £0.01 (', text_02: '£0.02)'
            },
            right: {
                text_01: 'GBP £1 (', text_02: '£2)'
            },
        },
        {
            left: 'EUR Euro',
            center: {
                text_01: 'EUR €0.015 (', text_02: '€0.03)'
            },
            right: {
                text_01: 'EUR €1.5 (', text_02: '€3)'
            },
        },
        {
            left: 'CAD Canadian Dollar',
            center: {
                text_01: 'CAD $0.02 (', text_02: '$0.04)'
            },
            right: {
                text_01: 'CAD $2 (', text_02: '$4)'
            },
        },
        {
            left: 'JPY Japanese Yen',
            center: {
                text_01: 'JPY ¥1.75 (', text_02: '¥3.50)'
            },
            right: {
                text_01: 'JPY ¥175 (', text_02: '¥350)'
            },
        },
    ],
];

const Commission = () => {
    const { t } = useTranslation();
    const [ selectedTab, setSelectedTab ] = useState(0);
    const [ currentRows, setCurrentRows ] = useState(rows[0]);
    useEffect(() => {
        setCurrentRows(rows[selectedTab]);
    }, [ selectedTab ]);
    return (
        <div className="container realtive">
            <div className={classes.commission}>
                <div className={classes.header}>
                    {t('comissions_title')}
                </div>
                <div className={classes.content}>
                    <div className={classes.switcher}>
                        <div 
                            className={[
                                classes.switcherBtn,
                                selectedTab === 0 && classes.active
                            ].join(' ')}
                            onClick={() => setSelectedTab(0)}
                        >
                            {t('comissions_standart')}
                        </div>
                        <div 
                            className={[
                                classes.switcherBtn,
                                selectedTab === 1 && classes.active
                            ].join(' ')}
                            onClick={() => setSelectedTab(1)}
                        >
                            {t('comissions_advanced_pro')}
                        </div>
                    </div>
                    <div className={classes.tbl}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>{t('comissions_col_01')}</td>
                                    <td><span>
                                        <Trans>
                                            {t('comissions_col_02')}
                                        </Trans>    
                                    </span></td>
                                    <td><span>
                                        <Trans>
                                            {t('comissions_col_03')}
                                        </Trans>    
                                    </span></td>
                                </tr>
                                {currentRows.map((row, index) => 
                                    <tr 
                                        key={index}
                                        className={`${index % 2 === 0 && classes.greyBg}`}
                                    >
                                        <td>{row.left}</td>
                                        <td>
                                            {t('comissions__round_turn', row.center)}
                                        </td>
                                        <td>
                                            {t('comissions__round_turn', row.right)}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={[classes.btn, 'btn-default'].join(' ')}>
                    <a href='https://user.vex-change.online/signup' className='btn-default'>
                        {t('start_trading')}   
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Commission;
