import React from "react";
import classes from "./Signals.module.scss";
import img from "../../../assets/img/TradingSpace/2/1.png";
import img1 from "../../../assets/img/TradingSpace/2/2.png";
import { useTranslation } from "react-i18next";

const Signals = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.signals}>
      <div className={classes.signalsBody}>
        <div className="container">
          <div className={classes.signalsRow}>
            <div className={classes.signalsRowLeft}>
              <div className={classes.signalsRowLeftTitle}>
                {t('signals_for_traders_title')}
              </div>
              <div className={classes.signalsRowLeftSubtitle}>
                {t('signals_for_traders_text')}
              </div>
            </div>
            <div className={classes.signalsRowRight}>
              <img src={img} alt="" />
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signals;
