import _ from 'lodash';
import React, { useState, useEffect } from "react";
import classes from "./Header.module.scss";
import logo from "../../../assets/img/header/logo.png";
import { headerRoutes } from "../../../config/routes/routes";
import { Link, useLocation } from "react-router-dom";
import i18n from "i18next";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import $ from 'jquery';

const Header = () => {
  const { t } = useTranslation();
  const [burgerA, setBurgerA] = useState(false);
  let location = useLocation();
  const [ selectedLang, setSelectedLang ] = useState(_.toUpper(Cookies.get("i18next")));
  useEffect(() => {
    i18n.changeLanguage(_.toLower(selectedLang))
  }, [selectedLang]);
  useEffect(() => {
    setBurgerA(false);
  }, [location.pathname]);
  useEffect(() => {
    if (t("lang") === "sa") {
      $("html").attr("dir", "rtl");
      $("body").addClass("sa");
    } else {
      $("html").attr("dir", "");
      $("body").removeClass("sa");
    }
    // eslint-disable-next-line
  }, [t("lang")]);
  return (
    <div className={classes.header}>
      <div className={classes.headerBody}>
        <div className="container">
          <div className={classes.headerRow}>
            <div
              className={
                burgerA
                  ? [classes.headerRowLogo, classes.active].join(" ")
                  : classes.headerRowLogo
              }
            >
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>

            <div className={classes.headerRowRight}>
              <div
                className={
                  burgerA
                    ? [classes.headerRowCenterWrapper, classes.active].join(" ")
                    : classes.headerRowCenterWrapper
                }
              >
                <div className={classes.headerRowCenter}>
                  {headerRoutes.map((i, idx) => (
                    <div key={i.text}>
                      <Link
                        to={i.path}
                        className={`${classes.headerRowCenterItem} ${location.pathname === i.path && classes.itemActive}`}
                      >
                        {t(i.text)} 
                      </Link>
                    </div>
                  ))}
                  <a href={`https://user.vex-change.online/login?lang=${t('lang_lk')}`} className={[classes.headerRowRightLogBtn, classes.headerRowRightLogBtnMob].join(' ')}>
                    {t("login")}
                  </a>
                  <a href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`} className={[classes.headerRowRightSignUpBtn, classes.headerRowRightSignUpBtnMob].join(' ')}>
                    {t("signup")}
                  </a>
                </div>
              </div>
              <div className={classes.headerRowRightLocalization}>
                <ReactFlagsSelect
                  fullWidth
                  selected={selectedLang}
                  onSelect={(code) => setSelectedLang(code)}
                  countries={["US", "RU", "DE", "ES", "IT", "PL", "SA", "RO", "RS"]}
                  className={classes.menuFlags}
                  selectButtonClassName={`${classes.menuFlagsButton} ${burgerA && classes.menuFlagsButtonLight}`}
                />
              </div>
              <a href={`https://user.vex-change.online/login?lang=${t('lang_lk')}`} className={classes.headerRowRightLogBtn}>
                {t("login")}
              </a>
              <a href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`} className={classes.headerRowRightSignUpBtn}>
                {t("signup")}
              </a>
              <div
                className={
                  burgerA
                    ? [classes.headerRowRightBurger, classes.active].join(" ")
                    : classes.headerRowRightBurger
                }
                onClick={() => setBurgerA(!burgerA)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
