import React from "react";
import { useTranslation } from "react-i18next";

const FifthTab = () => {
  const { t } = useTranslation();
  return (
    <p>
      {t('main_instruments_05_text')}
    </p>
  );
};

export default FifthTab;
