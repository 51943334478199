import React from "react";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/VattenfallPage/Banner/Banner";
import WhoWeAre from "../../components/VattenfallPage/WhoWeAre/WhoWeAre";
import Icons from "../../components/VattenfallPage/Icons/Icons";
import BestChoice from "../../components/VattenfallPage/BestChoice/BestChoice";
import Graphic from "../../components/VattenfallPage/Graphic/Graphic";

const VattenfallPage = () => {
    return (
        <div>
            <Banner />
            <WhoWeAre />
            <Icons />
            <BestChoice />
            <Graphic />
            <Footer />
        </div>
    );
};

export default VattenfallPage;
