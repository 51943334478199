import React from "react";
import { useTranslation } from "react-i18next";

const FourthTab = () => {
  const { t } = useTranslation();
  return (
    <p>
      {t('main_instruments_04_text')}
    </p>
  );
};

export default FourthTab;
