import React from "react";
import classes from './BestChoice.module.scss';
import { Trans, useTranslation } from "react-i18next";

const BestChoice = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.bestChoice}>
                <h2 className={classes.title}>
                    <Trans>
                        {t('best_choise_title')}
                    </Trans>
                </h2>
                <h4 className={classes.subtitle}>
                    {t('best_choise_subtitle')}
                </h4>
                <div className={classes.cols}>
                    <div className={classes.col}>
                        <p>
                            {t('best_choise_text_01')}
                        </p>
                        <p>
                            {t('best_choise_text_02')}
                        </p>
                        <p>
                            {t('best_choise_text_03')}
                        </p>
                        <p>
                            {t('best_choise_text_04')}
                        </p>
                        <p>
                            {t('best_choise_text_05')}
                        </p>
                    </div>
                    <div className={classes.col}>
                        <p>
                            {t('best_choise_text_06')}
                        </p>
                        <p>
                            {t('best_choise_text_07')}
                        </p>
                        <p>
                            {t('best_choise_text_08')}
                        </p>
                        <p>
                            {t('best_choise_text_09')}
                        </p>
                    </div>
                </div>
                <a href='https://user.vex-change.online/signup' id='btn' className={[classes.btn, 'btn-default'].join(' ')}>
                    {t('start_trading')}
                </a>
            </div>
        </section>
    );
};

export default BestChoice;
