import React, { useEffect, useState } from "react";
import classes from './TradingHours.module.scss';
import { useTranslation } from "react-i18next";

const rows = [
    [
        {
            left: 'FX',
            center: '00:00 — 23:58',
            right: '00:01 — 23:58',
        },
        {
            left: 'AUD/CNH',
            center: '00:00 — 23:57',
            right: '00:01 — 23:57',
        },
    ],
    [
        {
            left: 'Gold',
            center: 'M-T: 01:01-23:58 F: 01:01-23:57',
            right: 'M-T: 01:01-23:58 F: 01:01-23:57',
        },
        {
            left: 'Silver',
            center: 'M-T: 01:01-23:58 F: 01:01-23:57',
            right: 'M-T: 01:01-23:58 F: 01:01-23:57',
        },
        {
            left: 'Copper',
            center: 'M-F: 01:00-24:00 F: 01:01-23:57',
            right: 'M-F: 01:00-24:00 F: 01:01-23:57',
        },
        {
            left: 'XPTUSD',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'XPDUSD',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'NG',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'Gas',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'GASOIL-C',
            center: 'M 01:00-24:00 T-F 03:00-24:00',
            right: 'M 01:00-24:00 T-F 03:00-24:00',
        },
        {
            left: 'CL-OIL',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'USOUSD',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'UKOUSD',
            center: 'M 01:00-24:00 T-F 00:00-01:00; 03:00-24:00',
            right: 'M 01:00-24:00 T-F 00:00-01:00; 03:00-24:00',
        },
        {
            left: 'UKOUSDft',
            center: 'M 01:00-24:00 T-T 00:00-01:00; 03:00-24:00 F 00:00-01:00; 03:00-24:00',
            right: 'M 01:00-24:00 T-T 00:00-01:00; 03:00-24:00 F 00:00-01:00; 03:00-24:00',
        },
        {
            left: 'Cocoa-C',
            center: 'M-F 11:45-20:30',
            right: 'M-F 11:45-20:30',
        },
        {
            left: 'Coffee-C',
            center: 'M-F 11:15-20:30',
            right: 'M-F 11:15-20:30',
        },
        {
            left: 'Cotton-C',
            center: 'M-F 04:00-21:20',
            right: 'M-F 04:00-21:20',
        },
        {
            left: 'Orange-C',
            center: 'M-F 15:00-21:00',
            right: 'M-F 15:00-21:00',
        },
        {
            left: 'Sugar-C',
            center: 'M-F 10:30-20:00',
            right: 'M-F 10:30-20:00',
        },
        {
            left: 'Soybean-C',
            center: 'M-F 03:00-15:45; 16:30-21:20',
            right: 'M-F 03:00-15:45; 16:30-21:20',
        },
        {
            left: 'Wheat-C',
            center: 'M-F 03:00-15:45; 16:30-21:20',
            right: 'M-F 03:00-15:45; 16:30-21:20',
        },
    ],
    [
        {
            left: 'CHINA50',
            center: 'M-F 01:00-03:55 04:00-11:55 12:00-24:00',
            right: 'M-F 01:00-03:55 04:00-11:55 12:00-24:00',
        },
        {
            left: 'CHINA50ft',
            center: 'M-F 04:00-11:30 12:00-23:45',
            right: 'M-F 04:00-11:30 12:00-23:45',
        },
        {
            left: 'DAX40',
            center: 'M-T 03:15-24:00 F 03:15-23:00',
            right: 'M-T 03:15-24:00 F 03:15-23:00',
        },
        {
            left: 'DAX40ft',
            center: 'M-T 03:15-24:00 F 03:15-23:00',
            right: 'M-T 03:15-24:00 F 03:15-23:00',
        },
        {
            left: 'DJ30',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'DJ30ft',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'ES35',
            center: 'M-F 09:00-21:00',
            right: 'M-F 09:00-21:00',
        },
        {
            left: 'EU50',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'FRA40',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'FTSE100',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'FTSE100ft',
            center: 'M 01:05-24:00 T–T 01:00-24:00 F 01:00-23:00',
            right: 'M 01:05-24:00 T – T 01:00-24:00 F 01:00-23:00',
        },
        {
            left: 'HK50',
            center: 'M-F 04:15-07:00 08:00-11:30 12:15-22:00',
            right: 'M-F 04:15-07:00 08:00-11:30 12:15-22:00',
        },
        {
            left: 'HK50ft',
            center: 'M-F 04:15-07:00 08:00-11:30 12:15-22:00',
            right: 'M-F 04:15-07:00 08:00-11:30 12:15-22:00',
        },
        {
            left: 'INDIA50',
            center: 'M-F 10:30-20:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'NAS100',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'NAS100ft',
            center: 'M-T 01:05-24:00 F 01:00-23:00',
            right: 'M-T 01:05-24:00 F 01:00-23:00',
        },
        {
            left: 'Nikkei225',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'SA40',
            center: 'M-F 09:30-18:30',
            right: 'M-F 09:30-18:30',
        },
        {
            left: 'SP500',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'SP500ft',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'SPI200',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'US2000',
            center: 'M-F 01:00-24:00',
            right: 'M-F 01:00-24:00',
        },
        {
            left: 'USDX',
            center: 'M 01:00-24:00 T-T 03:00-24:00 F 03:00-23:00',
            right: 'M 01:00-24:00 T-T 03:00-24:00 F 03:00-23:00',
        },
        {
            left: 'VIX',
            center: 'M-T 01:00-24:00 F 01:00-23:15',
            right: 'M-T 01:00-24:00 F 01:00-23:15',
        },
    ],
    [
        {
            left: 'Shares',
            center: 'M-F 03:00-09:00',
            right: 'M-F 03:00-09:00',
        },
        {
            left: 'EU Shares',
            center: 'M-F 10:00-18:30',
            right: 'M-F 10:00-18:30',
        },
        {
            left: 'UK Shares',
            center: 'M-F 10:00-18:30',
            right: 'M-F 10:00-18:30',
        },
        {
            left: 'S Shares',
            center: 'M-F 16:30-23:00',
            right: 'M-F 16:30-23:00',
        },
    ],
    [
        {
            left: 'S&P 500',
            center: 'M-F  9:30 - 16:00',
            right: 'M-F  9:30 - 16:00',
        },
        {
            left: 'NASDAQ',
            center: 'M-F  9:30 - 16:30',
            right: 'M-F  9:30 - 16:30',
        },
        {
            left: 'Dow Jones',
            center: 'M-F  9:30 - 16:00',
            right: 'M-F  9:30 - 16:00',
        },
        {
            left: 'Nikkei',
            center: 'M-F 9:00 -  15:00',
            right: 'M-F 9:00 -  14:00',
        },
        {
            left: 'FTSE 100',
            center: 'M-F 8:00 - 16:30',
            right: 'M-F 8:00 - 16:30',
        },
        {
            left: 'DAX',
            center: 'M-F  9:00 - 17:30',
            right: 'M-F  15:00 - 23:30',
        },
        {
            left: 'CAC',
            center: 'M-F  9:00 - 17:30',
            right: 'M-F  15:00 - 23:30',
        },
    ],
];

const TradingHours = () => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(0);
    const [currentRows, setCurrentRows] = useState(rows[0]);
    useEffect(() => {
        setCurrentRows(rows[selectedTab]);
    }, [selectedTab]);
    return (
        <div className="container realtive">
            <div className={classes.commission}>
                <div className={classes.header}>
                    {t('trading_hours_title')}
                </div>
                <div className={classes.text}>
                    {t('trading_hours_text')}
                </div>
                <div className={classes.content}>
                    <div className={classes.switcher}>
                        <div
                            className={[
                                classes.switcherBtn,
                                selectedTab === 0 && classes.active
                            ].join(' ')}
                            onClick={() => setSelectedTab(0)}
                        >
                            {t('trading_hours_tab_01')}
                        </div>
                        <div
                            className={[
                                classes.switcherBtn,
                                selectedTab === 1 && classes.active
                            ].join(' ')}
                            onClick={() => setSelectedTab(1)}
                        >
                            {t('trading_hours_tab_02')}
                        </div>
                        <div
                            className={[
                                classes.switcherBtn,
                                selectedTab === 2 && classes.active
                            ].join(' ')}
                            onClick={() => setSelectedTab(2)}
                        >
                            {t('trading_hours_tab_03')}
                        </div>
                        <div
                            className={[
                                classes.switcherBtn,
                                selectedTab === 3 && classes.active
                            ].join(' ')}
                            onClick={() => setSelectedTab(3)}
                        >
                            {t('trading_hours_tab_04')}
                        </div>
                        <div
                            className={[
                                classes.switcherBtn,
                                selectedTab === 4 && classes.active
                            ].join(' ')}
                            onClick={() => setSelectedTab(4)}
                        >
                            {t('main_instruments_01_title')}
                        </div>
                    </div>
                    <div className={classes.tbl}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={classes.left}>{t('trading_hours_col_01')}</td>
                                    <td className={classes.center}>{t('trading_hours_col_02')}</td>
                                    <td className={classes.right}>{t('trading_hours_col_03')}</td>
                                </tr>
                                {currentRows.map((row, index) =>
                                    <tr key={index}>
                                        <td className={classes.left}>{row.left}</td>
                                        <td className={classes.center}>{row.center}</td>
                                        <td className={classes.right}>{row.right}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={[classes.btn, 'btn-default'].join(' ')}>
                    <a href='https://user.vex-change.online/signup' className='btn-default'>
                        {t('start_trading')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default TradingHours;
