import React, { useState } from "react";
import classes from "./Who.module.scss";
import img from "../../../assets/img/About/1/1.png";
import img1 from "../../../assets/img/About/1/2.png";
import img2 from "../../../assets/img/About/1/3.png";
import img3 from "../../../assets/img/About/1/4.png";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const Who = () => {
  const { t } = useTranslation();
  const [anim, setAnim] = useState(false);
  return (
    <div className={classes.who}>
      <div className={classes.whoBody}>
        <div className="container">
          <div className={classes.whoRow}>
            <div className={classes.whoRowLeft}>
              <div className={classes.whoRowLeftTitle}>
                <Trans components={{ span: <span></span> }}>
                  {t('about_banner_title')}
                </Trans>
              </div>
              <div className={classes.whoRowLeftSubtitle}>
                {t('about_banner_text')}
              </div>
              <a
                href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`}
                className={[classes.whoRowLeftStart, "btnHover"].join(" ")}
                onMouseEnter={() => {
                  setAnim(true);
                }}
                onMouseLeave={() => {
                  setAnim(false);
                }}
              >
                {t('start_trading')}
              </a>
            </div>
            <div className={classes.whoRowRight}>
              <img src={img} alt="" />
              <div
                className={
                  anim
                    ? [classes.whoRowRightFirstImg, classes.active].join(" ")
                    : classes.whoRowRightFirstImg
                }
              >
                <img src={img1} alt="" />
              </div>
              <div
                className={
                  anim
                    ? [classes.whoRowRightSecondImg, classes.active].join(" ")
                    : classes.whoRowRightSecondImg
                }
              >
                <img src={img2} alt="" />
              </div>
              <div
                className={
                  anim
                    ? [classes.whoRowRightThirdImg, classes.active].join(" ")
                    : classes.whoRowRightThirdImg
                }
              >
                <img src={img3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Who;
