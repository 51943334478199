import React from 'react';
import classes from './Banner.module.scss';
import vid from '../../../assets/videos/trading_conditions.mp4';
import img from '../../../assets/img/TradingConditionsPage/banner.png';
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <div className={classes.banner}>
            <div className={[classes.container, 'container', 'relative'].join(' ')}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <Trans components={{ span: <span></span> }}>
                            {t('trading_conditions_banner_title')}
                        </Trans>
                    </div>
                    <div className={classes.text}>
                        {t('trading_conditions_banner_text')}
                    </div>
                    <a href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`} className={[classes.btn, 'btn-default'].join(' ')}>
                        {t('start_trading')}
                    </a>
                </div>
                <video className={classes.vid} playsInline autoPlay loop muted>
                    <source src={vid} type="video/mp4" />
                </video>
                <img src={img} alt='' />
            </div>
        </div>
    );
};

export default Banner;
