import React from "react";
import classes from "./SubBlock.module.scss";
import { useTranslation } from "react-i18next";

const SubBlock = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.subblock}>
      <div className={classes.subblockBody}>
        <div className="container">
          <div className={classes.subblockRow}>
            <div className={classes.subblockRowLeft}>
              <div className={classes.subblockRowLeftTitle}>{t('who_we_title')}</div>
              <div className={classes.subblockRowLeftSubtitle}>
                {t('who_we_text_01')}
              </div>
              <div className={classes.subblockRowLeftSubtitle}>
                {t('who_we_text_02')}
              </div>
            </div>
            <div className={classes.subblockRowRight}>
              <div className={classes.subblockRowLeftSubtitle}>
                {t('who_we_text_03')}
              </div>
              <div className={classes.subblockRowLeftSubtitle}>
                {t('who_we_text_04')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubBlock;
