import React from "react";
import { useTranslation } from "react-i18next";

const FirstTab = () => {
  const { t } = useTranslation();
  return (
      <p>
        {t('main_instruments_01_text')}
      </p>
  );
};
export default FirstTab;
