import React from "react";
import classes from "../PlatformDifferent.module.scss";
import { useTranslation } from "react-i18next";

const SecondTab = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.platformDifferentTab}>
      <p>{t('what_differs_02_text')}</p>
    </div>
  );
};
export default SecondTab;
