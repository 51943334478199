import AboutPage from "../../pages/AboutPage/AboutPage"
import TradingSpacePage from "../../pages/TradingSpacePage/TradingSpacePage"
import TradingConditionsPage from "../../pages/TradingConditionsPage/TradingConditionsPage";
import HomePage from "../../pages/HomePage/HomePage";
import VattenfallPage from "../../pages/VattenfallPage/VattenfallPage";

export const routes = [
    { path: "/", element: HomePage },
    { path: "/trading-space", element: TradingSpacePage },
    { path: "/trading-conditions", element: TradingConditionsPage },
    { path: "/vattenfall", element: VattenfallPage },
    { path: "/about-us", element: AboutPage },
];

export const headerRoutes = [
    { path: "/trading-space", text: "trading_space" },
    { path: "/trading-conditions", text: "trading_conditions" },
    { path: "/vattenfall", text: "Vattenfall" },
    { path: "/about-us", text: "about_us" },
];
