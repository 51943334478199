import React, { useState } from "react";
import classes from "./PlatformDifferent.module.scss"
import FirstTab from "./Tabs/FirstTab";
import SecondTab from "./Tabs/SecondTab";
import ThirdTab from "./Tabs/ThirdTab";
import { Trans, useTranslation } from "react-i18next";

const PlatformDifferent = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const { t } = useTranslation();

  const handleTab = (e) => {
    setActiveTab(e.target.classList[0])
  };

  return (
    <div className={classes.platformDifferentWrap}>
      <div className="container">
        <h4 className={classes.platformDifferentTitle}>
          <Trans
            components={{span: <span></span>}}
          >
            {t('what_differs')}
          </Trans>
        </h4>
        <div className={classes.platformDifferentTabs}>
          <ul className={classes.platformDifferentNav}>
            <li
              className={[activeTab === "tab1" ? classes.active : "", "tab1"].join(' ')}
              onClick={handleTab}
            >
              {t('what_differs_01_title')}
            </li>
            <li
              className={[activeTab === "tab2" ? classes.active : "", "tab2"].join(' ')}
              onClick={handleTab}
            >
              {t('what_differs_02_title')}
            </li>
            <li
              className={[activeTab === "tab3" ? classes.active : "", "tab3"].join(' ')}
              onClick={handleTab}
            >
              {t('what_differs_03_title')}
            </li>
          </ul>
          <div className="outlet">
            <div className="outlet">
              {activeTab === "tab1" ? <FirstTab /> : activeTab === "tab2" ? <SecondTab /> : <ThirdTab />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlatformDifferent;
