import React, { useState, useEffect } from "react";
import classes from "./First.module.scss";
import img from "../../../assets/img/TradingSpace/1/1.png";
import { Trans, useTranslation } from "react-i18next";
import icon1 from "../../../assets/img/TradingSpace/1/gifs/1.gif";
import icon2 from "../../../assets/img/TradingSpace/1/gifs/2.gif";
import icon3 from "../../../assets/img/TradingSpace/1/gifs/3.gif";
import icon4 from "../../../assets/img/TradingSpace/1/gifs/4.gif";

const First = () => {
  const { t } = useTranslation();
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
  }, []);
  const handleScroll = () => {
    if (window.innerWidth > 992) {
      if (document.body.scrollTop > 250) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    } else {
      setScroll(false);
    }
  };
  return (
    <div className={classes.first}>
      <div className={classes.firstBody}>
        <div className={classes.firstFullscreen}>
          <div className="container">
            <div className={classes.top}>
              <div className={classes.topTitle}>
                <Trans
                  components={{ span: <span></span> }}
                >
                  {t('trading_space_banner_title')}
                </Trans>
              </div>
              <div className={classes.topSubtitle}>
                {t('trading_space_banner_subtitle')}
              </div>
              <div className={classes.topText}>
                {t('trading_space_banner_text')}
              </div>
              <a href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`} className={[classes.topStart, "btnHover"].join(" ")}>
                {t('start_earning')}
              </a>
            </div>
          </div>
          <div className={[classes.wawe, "wawe"].join(" ")}></div>
        </div>

        <div
          className={
            scroll ? [classes.phone, classes.active].join(" ") : classes.phone
          }
        >
          <div className="container">
            <div className={classes.phoneWrap}>
              <img src={img} alt="" />
            </div>
          </div>
        </div>
        <div className={classes.bot}>
          <div className={classes.botBody}>
            <div className="container">
              <div className={classes.botTitle}>
                {t('trade_forex_title')}
              </div>
              <div className={classes.botRow}>
                <div className={classes.botRowIcon}>
                  <img src={icon1} alt="" />
                </div>
                <div className={classes.botRowContent}>
                  <div className={classes.botRowContentTitle}>
                    {t('trade_forex_01_title')}
                  </div>
                  <div className={classes.botRowContentSubtitle}>
                    {t('trade_forex_01_text')}
                  </div>
                </div>
              </div>
              <div className={classes.botRow}>
                <div className={classes.botRowIcon}>
                  <img src={icon2} alt="" />
                </div>
                <div className={classes.botRowContent}>
                  <div className={classes.botRowContentTitle}>
                    {t('trade_forex_02_title')}
                  </div>
                  <div className={classes.botRowContentSubtitle}>
                    {t('trade_forex_02_text')}
                  </div>
                </div>
              </div>
              <div className={classes.botRow}>
                <div className={classes.botRowIcon}>
                  <img src={icon3} alt="" />
                </div>
                <div className={classes.botRowContent}>
                  <div className={classes.botRowContentTitle}>
                    {t('trade_forex_03_title')}
                  </div>
                  <div className={classes.botRowContentSubtitle}>
                    {t('trade_forex_03_text')}
                  </div>
                </div>
              </div>
              <div className={classes.botRow}>
                <div className={classes.botRowIcon}>
                  <img src={icon4} alt="" />
                </div>
                <div className={classes.botRowContent}>
                  <div className={classes.botRowContentTitle}>
                    {t('trade_forex_04_title')}
                  </div>
                  <div className={classes.botRowContentSubtitle}>
                    {t('trade_forex_04_text')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
