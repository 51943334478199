import React from "react";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Fast from "../../components/TradingSpaceComponents/Fast/Fast";
import First from "../../components/TradingSpaceComponents/First/First";
import Have from "../../components/TradingSpaceComponents/Have/Have";
import Signals from "../../components/TradingSpaceComponents/Signals/Signals";

const TradingSpacePage = () => {
  return (
    <div>
      <First />
      <Have />
      <Signals />
      <Fast />
      <Footer />
    </div>
  );
};

export default TradingSpacePage;
