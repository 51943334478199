import React from "react";
import classes from "./HaveQuestion.module.scss"
import { useTranslation } from "react-i18next";

const HaveQuestion = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.HaveQuestionWrap}>
      <div className="container">
        <div className={classes.HaveQuestionRow}>
          <p className={classes.HaveQuestionText}>{t('any_questions')}</p>
          <a href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`} className={classes.HaveQuestionLink}>{t('join_live_chat')}</a>
        </div>
      </div>
    </div>
  )
}

export default HaveQuestion;
