import React from "react";
import classes from "./ShortOverview.module.scss"
import phoneImg from "../../../assets/img/Home/phone_img.png"
import { Trans, useTranslation } from "react-i18next";

const ShortOverview = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.shortOverviewWrap} id="shortOverview">
      <div className="container">
        <div className={classes.shortOverviewRow}>
          <div className={classes.col8}>
            <div className={classes.shortOverviewCard}>
              <h5 className={classes.shortOverviewTitle}>
                <Trans
                  components={{span: <span></span>}}
                >
                  {t('short_overview_title')}
                </Trans>
              </h5>
              <p className={[classes.shortOverviewText, classes.mb40].join(' ')}>
                {t('short_overview_text_01')}
              </p>
              <p className={[classes.shortOverviewText, classes.mt40].join(' ')}>
                {t('short_overview_text_02')}
              </p>
              <p className={[classes.shortOverviewText, classes.mt40].join(' ')}>
                {t('short_overview_text_03')}
              </p>
            </div>
          </div>
          <div className={classes.col4}>
            <img src={phoneImg} alt=""/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShortOverview;
