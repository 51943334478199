import React, { useState } from "react";
import classes from "./Benefits.module.scss";
import img1 from "../../../assets/img/About/2/1.png";
import img2 from "../../../assets/img/About/2/2.png";
import img3 from "../../../assets/img/About/2/3.png";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";

const Benefits = () => {
  const { t } = useTranslation();
  const [slider, setSlider] = useState(null);
  const nextSlide = () => {
    slider.slideNext();
  };
  const [sliderColumns, setSliderColums] = useState([
    {
      id: 1,
      title: "benefits_card_title_01",
      text: "benefits_card_text_01",
      anim: 1,
    },
    {
      id: 2,
      title: "benefits_card_title_02",
      text: "benefits_card_text_02",
      anim: 2,
    },
    {
      id: 3,
      title: "benefits_card_title_03",
      text: "benefits_card_text_03",
      anim: 3,
    },
    {
      id: 4,
      title: "benefits_card_title_04",
      text: "benefits_card_text_04",
      anim: 4,
    },
    {
      id: 5,
      title: "benefits_card_title_05",
      text: "benefits_card_text_05",
      anim: 5,
    },
    {
      id: 6,
      title: "benefits_card_title_06",
      text: "benefits_card_text_06",
      anim: 6,
    },
    // {
    //   id: 7,
    //   title: "benefits_card_title_07",
    //   text: "benefits_card_text_07",
    //   anim: 7,
    // },
    {
      id: 8,
      title: "benefits_card_title_08",
      text: "benefits_card_text_08",
      anim: 7,
    },
    {
      id: 9,
      title: "benefits_card_title_09",
      text: "benefits_card_text_09",
      anim: 8,
    },
  ]);
  return (
    <div className={classes.benefits}>
      <div className={classes.benefitsBody}>
        <div className="container">
          <div className={classes.benefitsTitle}>{t("benefits_title")}</div>
          <div className={classes.benefitsSubtitle}>{t("benefits_text")}</div>
          <div className={classes.benefitsRow}>
            <div className={classes.benefitsRowLeft}>
              <div className={classes.benefitsRowLeftSlider}>
                <Swiper
                  slidesPerView={4}
                  loop={true}
                  allowTouchMove={false}
                  onSlideChange={() => {
                    if (slider) {
                      setSliderColums(
                        sliderColumns.map((i) => {
                          if (i.anim !== 1) {
                            return { ...i, anim: i.anim - 1 };
                          } else {
                            return { ...i, anim: 8 };
                          }
                        })
                      );
                    }
                  }}
                  breakpoints={{
                    601: {
                      slidesPerView: 4,
                    },
                    100: {
                      slidesPerView: 1,
                      centeredSlides: true,
                      centeredSlidesBounds: true,
                      centerInsufficientSlides: true,
                    },
                  }}
                  speed={1200}
                  onInit={(ev) => {
                    setSlider(ev);
                  }}
                >
                  {sliderColumns.map((slide) => (
                    <SwiperSlide key={slide.id}>
                      <div
                        className={[
                          classes.sliderColumn,
                          `sliderColumnAnim${slide.anim}`,
                        ].join(" ")}
                      >
                        <div className={classes.sliderColumnTitle}>
                          {t(slide.title)}
                        </div>
                        <div className={classes.sliderColumnSubtitle}>
                          {t(slide.text)}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className={classes.benefitsRowLeftArrows}>
                <div
                  className={classes.benefitsRowLeftArrowsRight}
                  onClick={() => {
                    nextSlide();
                  }}
                ></div>
              </div>
            </div>
            <div className={classes.benefitsRowRight}>
              <div className={classes.benefitsRowRightIcon1}>
                <img src={img1} alt="" />
              </div>
              <div className={classes.benefitsRowRightIcon2}>
                <img src={img2} alt="" />
              </div>
              <div className={classes.benefitsRowRightIcon3}>
                <img src={img3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
