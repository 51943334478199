import React from "react";
import classes from './Icons.module.scss';
import icon_01 from '../../../assets/img/VattenfallPage/Icons/icon_01.png';
import icon_02 from '../../../assets/img/VattenfallPage/Icons/icon_02.png';
import icon_03 from '../../../assets/img/VattenfallPage/Icons/icon_03.png';
import icon_04 from '../../../assets/img/VattenfallPage/Icons/icon_04.png';
import icon_05 from '../../../assets/img/VattenfallPage/Icons/icon_05.png';
import { Trans, useTranslation } from "react-i18next";

const cards = [
    {
        icon: icon_01,
        title: 'cards_card_title_01',
        text: 'cards_card_text_01',
    },
    {
        icon: icon_02,
        title: 'cards_card_title_02',
        text: 'cards_card_text_02',
    },
    {
        icon: icon_03,
        title: 'cards_card_title_03',
        text: 'cards_card_text_03',
    },
    {
        icon: icon_04,
        title: 'cards_card_title_04',
        text: 'cards_card_text_04',
    },
    {
        icon: icon_05,
        title: 'cards_card_title_05',
        text: 'cards_card_text_05',
    },
];

const Icons = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.icons}>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img src={card.icon} alt=''/>
                            <h4 className={classes.cardTitle}>
                                {t(card.title)}
                            </h4>
                            <p className={classes.cardText}>
                                <Trans>
                                    {t(card.text)}
                                </Trans>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Icons;
