import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import classes from "./Banner.module.scss";
import icon1 from "../../../assets/img/Home/banner-icon-1.png";
import icon2 from "../../../assets/img/Home/banner-icon-2.png";
import icon3 from "../../../assets/img/Home/banner-icon-3.png";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (ref !== null) {
      if (document.documentElement.clientWidth > 1220) {
        if (document.documentElement.clientWidth > 1650) {
          gsap.timeline().to(".animation-img-1", {
            y: 1100,
            scrollTrigger: {
              trigger: ".animation-img-1",
              start: "top 100px",
              end: "bottom 0px",
              scrub: 3,
              id: "scrub"
            }
          }).to(".animation-img-2", {
            y: 1100,
            scrollTrigger: {
              trigger: ".animation-img-2",
              start: "top 500px",
              end: "bottom 0px",
              scrub: 3,
              id: "scrub",
            }
          }).to(".animation-img-3", {
            y: 1300,
            x: 200,
            scrollTrigger: {
              trigger: ".animation-img-3",
              start: "top 500px",
              end: "bottom 0px",
              scrub: 1,
              id: "scrub",
            }
          });
        } else {
          gsap.timeline().to(".animation-img-1", {
            y: 1100,
            scrollTrigger: {
              trigger: ".animation-img-1",
              start: "top 100px",
              end: "bottom 0px",
              scrub: 3,
              id: "scrub"
            }
          }).to(".animation-img-2", {
            y: 950,
            x: 200,
            scrollTrigger: {
              trigger: ".animation-img-2",
              start: "top 500px",
              end: "bottom 0px",
              scrub: 3,
              id: "scrub",
            }
          }).to(".animation-img-3", {
            y: 1300,
            x: 200,
            scrollTrigger: {
              trigger: ".animation-img-3",
              start: "top 500px",
              end: "bottom 0px",
              scrub: 1,
              id: "scrub",
            }
          });
        }
      }
    } 
  }, []);
  return (
    <div className={classes.bannerWrap}>
      <div className='container'>
        <div className={classes.bannerRow}>
          <div className={classes.col8}>
            <div className={classes.bannerTextWrap}>
              <h1 className={classes.bannerTitle}>
                <Trans
                  components={{span: <span></span>}}
                >{t('home_banner_header')}</Trans>
              </h1>
              <p className={classes.bannerText}>
              {t('home_banner_text')}
              </p>
              <a href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`} className="btn-default">{t('start_earning')}</a>
            </div>
          </div>
          <div className={classes.col4}>
            <div className={classes.bannerImagesWrap}>
              <img src={icon1} className="animation-img-1" alt=""/>
              <img src={icon2} className="animation-img-2" alt=""/>
              <img src={icon3} className="animation-img-3" alt=""/>
            </div>
          </div>
        </div>
        <div className={[classes.wawe, "wawe"].join(" ")}></div>
      </div>
    </div>
  )
}

export default Banner;
