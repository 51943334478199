import React from "react";
import classes from "./OurPlatforms.module.scss"
import ourTradeImg from "../../../assets/img/Home/our-trade-img.png"
import { Trans, useTranslation } from "react-i18next";

const OurPlatforms = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.ourPlatformsWrap} id="ourPlatforms">
      <div className="container">
        <div className={classes.ourPlatformsRow}>
          <div className={classes.col8}>
            <h4 className={classes.ourPlatformsTitle}>
              <Trans
                components={{span: <span></span>}}
              >
                {t('our_platforms_title')}
              </Trans>
            </h4>
            <p className={classes.ourPlatformsText}>
              <Trans
                components={{span: <span></span>}}
              >
                {t('our_platforms_text')}
              </Trans>
            </p>
          </div>
          <div className={classes.col4}>
            <img src={ourTradeImg} alt=""/>
          </div>
        </div>
        <div className={classes.ourPlatformStatWrap}>
          <div className={classes.col}>
            <h5 className={[classes.ourPlatformStatNumber, classes.ourPlatformStatNumberDollar].join(' ')}>
              {t('our_platforms_01_num')}
            </h5>
            <p className={classes.ourPlatformStatText}>
              <Trans>
                {t('our_platforms_01_text')}
              </Trans>
            </p>
          </div>
          <div className={classes.col}>
            <h5 className={classes.ourPlatformStatNumber}>
              {t('our_platforms_02_num')}
            </h5>
            <p className={classes.ourPlatformStatText}>
              <Trans>
                {t('our_platforms_02_text')}
              </Trans>
            </p>
          </div>
          <div className={classes.col}>
            <h5 className={classes.ourPlatformStatNumber}>
              {t('our_platforms_03_num')}
            </h5>
            <p className={classes.ourPlatformStatText}>
              <Trans>
                {t('our_platforms_03_text')}
              </Trans>
            </p>
          </div>
          <div className={classes.col}>
            <h5 className={classes.ourPlatformStatNumber}>
              {t('our_platforms_04_num')}
            </h5>
            <p className={classes.ourPlatformStatText}>
              <Trans>
                {t('our_platforms_04_text')}
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurPlatforms;
