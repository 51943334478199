import React from "react";
import classes from "./Fast.module.scss";
import { Trans, useTranslation } from "react-i18next";

const Fast = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.fast}>
      <div className={classes.fastBody}>
        <div className="container">
          <div className={classes.fastRow}>
            <div className={classes.fastLeft}>
              <div className={classes.fastRowRightTitle}>{t('fast_ordering_title_03')}</div>
              <div className={[classes.fastRowRightSubtitle, classes.fastRowRightSubtitle_01].join(' ')}>
                <Trans components={{ span: <span></span> }}>
                  {t('fast_ordering_text_07')}
                </Trans>
              </div>
              <div className={classes.fastRowRightSubtitle}>{t('fast_ordering_text_08')}</div>
              <a
                href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`}
                className={[classes.fastRowRightStart, "btnHover"].join(" ")}
              > {t('start_trading')} </a>
            </div>
            <div className={classes.fastRowRight}>
              <div className={classes.fastLeftTitle}>{t('fast_ordering_title_01')}</div>
              <div className={classes.fastLeftSubtitle}>{t('fast_ordering_text_01')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fast;
