import React, { useState } from "react";
import classes from "./MainInstruments.module.scss";
import { useTranslation } from "react-i18next";
import mainInstImg from "../../../assets/img/Home/cryptocurrency.png";
import FirstTab from "../MainInstruments/Tabs/FirstTab";
import SecondTab from "../MainInstruments/Tabs/SecondTab";
import ThirdTab from "../MainInstruments/Tabs/ThirdTab";
import FourthTab from "../MainInstruments/Tabs/FourthTab";
import FifthTab from '../MainInstruments/Tabs/FifthTab';

const MainInstruments = () => {
  const { t } = useTranslation();
  const [activeTabDown, setActiveTabDown] = useState("tabDown1");
  const handleTab = (e) => {
    setActiveTabDown(e.target.classList[0])
  };
  return (
    <div className={classes.mainInstrumentsWrap} id="mainInstruments">
      <h4 className={classes.mainInstrumentsTitle}>{t('main_instruments_title')}</h4>
      <div className="container">
        <div className={classes.mainInstrumentsRow}>
          <div className={classes.col8}>
            <div className={classes.mainInstrumentsTabs}>
              <ul className={classes.mainInstrumentsNav}>
                <li
                  className={[activeTabDown === "tabDown1" ? classes.active : "", 'tabDown1'].join(' ')}
                  onClick={handleTab}
                >
                  {t('main_instruments_01_title')}
                </li>
                <li
                  className={[activeTabDown === "tabDown2" ? classes.active : "", 'tabDown2'].join(' ')}
                  onClick={handleTab}
                >
                  {t('main_instruments_02_title')}
                </li>
                <li
                  className={[activeTabDown === "tabDown3" ? classes.active : "", 'tabDown3'].join(' ')}
                  onClick={handleTab}
                >
                  {t('main_instruments_03_title')}
                </li>
                <li
                  className={[activeTabDown === "tabDown4" ? classes.active : "", 'tabDown4'].join(' ')}
                  onClick={handleTab}
                >
                  {t('main_instruments_04_title')}
                </li>
                <li
                  className={[activeTabDown === "tabDown5" ? classes.active : "", 'tabDown5'].join(' ')}
                  onClick={handleTab}
                >
                  {t('main_instruments_05_title')}
                </li>
              </ul>
              <div className={classes.mainInstrumentsTabsWrap}>
                <div className={classes.mainInstrumentsTabsText}>
                  {activeTabDown === "tabDown1" ? <FirstTab /> : activeTabDown === "tabDown2" ? <SecondTab /> : activeTabDown === "tabDown3" ? <ThirdTab /> : activeTabDown === "tabDown4" ? <FourthTab /> : <FifthTab />}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.col4}>
            <img src={mainInstImg} alt=""/>
          </div>
        </div>

      </div>
    </div>
  )
}

export default MainInstruments;
