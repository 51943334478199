import React from "react";
import classes from "./TableCoins.module.scss"
import { useTranslation } from "react-i18next";

const TableCoins = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.tableCoinsWrap}>
      <div className="container">
        <div className={classes.tableCoinsRow}>
          <div className={[classes.col3, classes.tableCoinsHeadRow].join(' ')}>{t('table_coins_coin')}</div>
          <div className={[classes.col3, classes.tableCoinsHeadRow].join(' ')}>{t('table_coins_apy')}</div>
          <div className={[classes.col3, classes.tableCoinsHeadRow].join(' ')}>{t('table_coins_term')}</div>
          <div className={[classes.col3, classes.tableCoinsHeadRow].join(' ')}></div>
          <div className={classes.col3}>BitCoin (BTC)</div>
          <div className={classes.col3}>3,2 %</div>
          <div className={classes.col3}>{t('table_coins_term_flexible')}</div>
          <div className={classes.col3}><a className="btn-default" href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`}>{t('start_earning')}</a></div>
          <div className={classes.col3}>Ether (ETH)</div>
          <div className={classes.col3}>2,5 %</div>
          <div className={classes.col3}>{t('table_coins_term_flexible')}</div>
          <div className={classes.col3}><a className="btn-default" href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`}>{t('start_earning')}</a></div>
          <div className={classes.col3}>USD Tether (USDT)</div>
          <div className={classes.col3}>0,51 %</div>
          <div className={classes.col3}>{t('table_coins_term_flexible')}</div>
          <div className={classes.col3}><a className="btn-default" href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`}>{t('start_earning')}</a></div>
          <div className={classes.col3}>USD Coin (USD)</div>
          <div className={classes.col3}>2,061 %</div>
          <div className={classes.col3}>{t('table_coins_term_flexible')}</div>
          <div className={classes.col3}><a className="btn-default" href={`https://user.vex-change.online/signup?lang=${t('lang_lk')}`}>{t('start_earning')}</a></div>
        </div>
      </div>
    </div>
  )
}

export default TableCoins;
