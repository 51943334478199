import React from "react";
import classes from './Industry.module.scss';
import { Trans, useTranslation } from "react-i18next";
import check_true from '../../../assets/img/TradingConditionsPage/check_true.svg';
import check_false from '../../../assets/img/TradingConditionsPage/check_false.svg';
import arrow from '../../../assets/img/TradingConditionsPage/arrow.gif';
import platforms from '../../../assets/img/TradingConditionsPage/platforms.svg';

const cards = [
    {
        name: 'industry_card_01_title',
        col_01: '249$',
        col_02: 'industry_card_row_02_card_01',
        col_03: '1:100',
        col_04: 'industry_card_row_04_card_01',
        col_05: '',
        col_06: '0,01',
        col_07: '20%',
        col_08: true,
        col_09: true,
        col_10: false,
        col_11: false,
    },
    {
        name: 'industry_card_02_title',
        col_01: '1.000$',
        col_02: 'industry_card_row_02_card_02',
        col_03: '1:100',
        col_04: 'industry_card_row_04_card_02',
        col_05: '',
        col_06: '0,01',
        col_07: '20%',
        col_08: true,
        col_09: true,
        col_10: false,
        col_11: false,
    },
    {
        name: 'industry_card_03_title',
        col_01: '10.000$',
        col_02: 'industry_card_row_02_card_03',
        col_03: '1:100',
        col_04: 'industry_card_row_04_card_03',
        col_05: '',
        col_06: '0,01',
        col_07: '20%',
        col_08: true,
        col_09: true,
        col_10: true,
        col_11: false,
    },
    {
        name: 'GOLD',
        col_01: '50.000$',
        col_02: 'industry_card_row_02_card_04',
        col_03: '1:100',
        col_04: 'industry_card_row_04_card_04',
        col_05: '',
        col_06: '0,01',
        col_07: '20%',
        col_08: true,
        col_09: true,
        col_10: true,
        col_11: false,
    },
    {
        name: 'PLATINUM',
        col_01: '100.000$',
        col_02: 'industry_card_row_02_card_05',
        col_03: '1:100',
        col_04: 'industry_card_row_04_card_05',
        col_05: '',
        col_06: '0,01',
        col_07: '20%',
        col_08: true,
        col_09: true,
        col_10: true,
        col_11: true,
    },
];

const Industry = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.container, 'container'].join(' ')}>
            <div className={classes.industry}>
                <div className={classes.header}>
                    {t('industry_title')}
                </div>
                <div className={classes.text}>
                    <div>
                        {t('industry_text_01')}
                    </div>
                    <div className={classes.par}>
                        {t('industry_text_02')}
                    </div>
                </div>
                <div className={classes.cardsWr}>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div className={classes.card} key={index}>
                                <div className={classes.cardHeader}>
                                    {t(card.name)}
                                </div>
                                <div className={classes.cardContent}>
                                    <table className={classes.visibleTable}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {t('industry_card_min_deposit')}
                                                </td>
                                                <td>
                                                    {card.col_01}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Trans>
                                                        {t('industry_card_row_02_title')}
                                                    </Trans>
                                                </td>
                                                <td>
                                                    {t(card.col_02)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {t('industry_card_row_03_title')}
                                                </td>
                                                <td>
                                                    {card.col_03}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {t('industry_card_row_04_title')}
                                                </td>
                                                <td>
                                                    {t(card.col_04)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Trans>
                                                        {t('industry_card_row_05_title')}
                                                    </Trans>
                                                </td>
                                                <td>
                                                    <img src={platforms} alt=''/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Trans>
                                                        {t('industry_card_row_06_title')}
                                                    </Trans>
                                                </td>
                                                <td>
                                                    {card.col_06}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className={classes.hidden}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {t('industry_card_row_07_title')}
                                                    </td>
                                                    <td>
                                                        {card.col_07}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {t('industry_card_row_08_title')}
                                                    </td>
                                                    <td>
                                                        {card.col_08 ? <img src={check_true} alt=''/> : <img src={check_false} alt=''/>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {t('industry_card_row_09_title')}
                                                    </td>
                                                    <td>
                                                        {card.col_09 ? <img src={check_true} alt=''/> : <img src={check_false} alt=''/>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {t('industry_card_row_10_title')}
                                                    </td>
                                                    <td>
                                                        {card.col_10 ? <img src={check_true} alt=''/> : <img src={check_false} alt=''/>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Trans>
                                                            {t('industry_card_row_11_title')}
                                                        </Trans>
                                                    </td>
                                                    <td>
                                                        {card.col_11 ? <img src={check_true} alt=''/> : <img src={check_false} alt=''/>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <img className={classes.cardArrow} src={arrow} alt=''/>                   
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Industry;
