import React from 'react';
import { useEffect } from 'react';
import classes from './Spreads.module.scss';
import img from '../../../assets/img/TradingConditionsPage/spreads.png';
import gsap from 'gsap';
import { useTranslation } from "react-i18next";

const Spreads = () => {
    const { t } = useTranslation();
    useEffect(() => {
        const block = document.querySelector('#btn');
        if (block) {
            block.addEventListener('mouseenter', move);
            block.addEventListener('mouseleave', moveBack);
        }
    }, []);
    const move = () => {
        gsap.to('#img', { scale: 1.05, duration: .5 });
    };
    const moveBack = () => {
        gsap.to('#img', { scale: 1, duration: .5 });
    };
    return (
        <div className='container relative'>
            <div className={classes.spreads}>
                <div className={classes.header}>
                    {t('spreads_title')}
                </div>
                <div className={classes.second}>
                    {t('spreads_subtitle')}
                </div>
                <div className={classes.text}>
                    <div className={classes.par}>
                        {t('spreads_text_01')}
                    </div>
                    <div className={classes.par}>
                        {t('spreads_text_02')}
                    </div>
                </div>
                <a href='https://user.vex-change.online/signup' id='btn' className={[classes.btn, 'btn-default'].join(' ')}>
                    {t('start_trading')}
                </a>
            </div>
            <img id='img' className={[classes.image, 'no-select', classes.img1].join(' ')} src={img} alt='' />
        </div>
    );
};

export default Spreads;
