import React from "react";
import Banner from "../../components/HomePage/Banner/Banner";
import StartTrade from "../../components/HomePage/StartTrade/StartTrade";
import HaveQuestion from "../../components/HomePage/HaveQuestion/HaveQuestion";
import OurPlatforms from "../../components/HomePage/OurPlatforms/OurPlatforms";
import PlatformDifferent from "../../components/HomePage/PlatformDifferent/PlatformDifferent";
import ShortOverview from "../../components/HomePage/ShortOverview/ShortOverview";
import MainInstruments from "../../components/HomePage/MainInstruments/MainInstruments";
import TableCoins from "../../components/HomePage/TableCoins/TableCoins";
import Footer from "../../components/GlobalComponents/Footer/Footer";

const HomePage = () => {
  return (
    <div>
      <Banner />
      <StartTrade />
      <HaveQuestion />
      <OurPlatforms />
      <PlatformDifferent />
      <ShortOverview />
      <MainInstruments />
      <TableCoins />
      <Footer />
    </div>
  )
}

export default HomePage;
