import React from "react";
import classes from './ForexSwap.module.scss';
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const ForexSwap = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.swap}>
                <div className={classes.row}>
                    <div className={classes.col}>
                        <div className={classes.header}>
                            <Trans components={{ span: <span></span> }}>
                                {t('forex_swap_title_01')}
                            </Trans>
                        </div>
                        <div className={classes.text}>
                            <div>{t('forex_swap_text_01')}</div>
                            <div className={classes.par}>{t('forex_swap_text_02')}</div>
                            <div className={classes.par}>{t('forex_swap_text_03')}</div>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.header}>
                            <Trans components={{ span: <span></span> }}>
                                {t('forex_swap_title_04')}
                            </Trans>
                        </div>
                        <div className={classes.text}>
                            <div>{t('forex_swap_text_07')}</div>
                            <div className={classes.par}>{t('forex_swap_text_08')}</div>
                        </div>
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.col}>
                        <div className={classes.header}>
                            <Trans components={{ span: <span></span> }}>
                                {t('forex_swap_title_03')}
                            </Trans>
                        </div>
                        <div className={classes.text}>
                            <div>{t('forex_swap_text_05')}</div>
                            <div className={classes.par}>{t('forex_swap_text_06')}</div>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.header}>
                            <Trans components={{ span: <span></span> }}>
                                {t('forex_swap_title_02')}
                            </Trans>
                        </div>
                        <div className={classes.text}>{t('forex_swap_text_04')}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForexSwap;
