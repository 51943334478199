import logo from '../assets/img/header/logo.png';
// home
import homeBannerIcon_01 from '../assets/img/Home/banner-icon-1.png';
import homeBannerIcon_02 from '../assets/img/Home/banner-icon-2.png';
import homeBannerIcon_03 from '../assets/img/Home/banner-icon-3.png';
//trading space
import tradingSpaceBannerImage from '../assets/img/TradingSpace/1/1.png';
//trading conditions
import tradingConditionBannerImage from '../assets/img/TradingConditionsPage/banner.png';
// about us
import aboutUsBannerImage_01 from '../assets/img/About/1/1.png';
import aboutUsBannerImage_02 from '../assets/img/About/1/2.png';
import aboutUsBannerImage_03 from '../assets/img/About/1/3.png';
import aboutUsBannerImage_04 from '../assets/img/About/1/4.png';

export const images = [
    logo,
    homeBannerIcon_01,
    homeBannerIcon_02,
    homeBannerIcon_03,
    tradingSpaceBannerImage,
    tradingConditionBannerImage,
    aboutUsBannerImage_01,
    aboutUsBannerImage_02,
    aboutUsBannerImage_03,
    aboutUsBannerImage_04,
];